
import {
  computed, defineComponent, PropType, toRefs,
} from 'vue';
import { IAtom, getAtomCover } from '@/domains/atom';
import { useI18n } from '@/plugins/vue-i18n';
import { useImageLoader, useRef } from '@/composables';
import { Banner } from '@/types/banner';
import ActionMenu from '@/components/ui/ActionMenu';
import DialogComponent from '@/components/ui/DialogComponent';
import BaseChip from '@/components/ui/BaseChip';
import ProvidersChip from '@/components/ui/ProvidersChip';

enum IPrimaryButtonType {
  START = 'start',
  CONTINUE = 'continue',
  RESTART = 'restart',
  ASSIGN = 'assign',
  RESULTS = 'results',
  UNKNOWN = 'unknown',
}

export default defineComponent({
  name: 'AtomPreview',

  components: {
    ActionMenu, DialogComponent, BaseChip, ProvidersChip,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    isAssignmentLoading: {
      type: Boolean,
      default: false,
    },
    atom: {
      type: Object as PropType<IAtom>,
      required: true,
    },
    isStartAvailable: {
      type: Boolean,
      default: false,
    },
    isContinueAvailable: {
      type: Boolean,
      default: false,
    },
    isRestartAvailable: {
      type: Boolean,
      default: false,
    },
    isAssignAvailable: {
      type: Boolean,
      default: false,
    },
    isUnassignAvailable: {
      type: Boolean,
      default: false,
    },
    isResultsAvailable: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['click:start', 'click:continue', 'click:restart', 'click:assign', 'click:unassign', 'click:results'],

  setup(props, { emit }) {
    const {
      atom,
      isStartAvailable,
      isContinueAvailable,
      isRestartAvailable,
      isAssignAvailable,
      isUnassignAvailable,
      isResultsAvailable,
    } = toRefs(props);

    const { t } = useI18n();

    const [unassignDialogRef, setUnassignDialogRef] = useRef<Banner>();

    const { isImageLoading, onLoadStartHandler, onLoadErrorHandler } = useImageLoader();

    const atomCoverUrl = computed<string>(() => getAtomCover(atom.value.id));

    const atomDurationFormatted = computed<string>(() => {
      const hours = Math.floor((atom.value.duration ?? 0) / 60);
      const minutes = Math.round((atom.value.duration ?? 0) % 60);
      if (hours && minutes) {
        return `${hours} ч ${minutes} мин`;
      }
      if (hours && !minutes) {
        return `${hours} ч`;
      }
      return `${minutes} мин`;
    });

    const atomHasProviders = computed<boolean>(() => atom.value.providers && atom.value.providers.length > 0);

    const onClickPrimaryActionHandler = () => {
      // eslint-disable-next-line default-case
      switch (true) {
        case isStartAvailable.value:
          emit('click:start');
          return;
        case isContinueAvailable.value:
          emit('click:continue');
          return;
        case isRestartAvailable.value:
          emit('click:restart');
          return;
        case isAssignAvailable.value:
          emit('click:assign');
          return;
        case isResultsAvailable.value:
          emit('click:results');
      }
    };

    const onClickUnassignHandler = () => {
      let resolve = () => {};
      let reject = () => {};

      const result = new Promise<void>((res, rej) => {
        resolve = res;
        reject = rej;
      });

      emit('click:unassign', { resolve, reject });

      result
        .then(() => {
          unassignDialogRef.value?.close();
        })
        .catch(() => {
          unassignDialogRef.value?.close();
        });
    };

    const openDialog = () => {
      unassignDialogRef.value?.open();
    };

    const rejectDialog = () => {
      unassignDialogRef.value?.close();
    };

    const dropdownMenuActions = computed(() => {
      const actions = [];

      if (isUnassignAvailable.value) {
        actions.push({
          value: 'delete',
          title: t('AtomPreview.actions.unassign'),
          icon: '$remove',
          color: 'tt-light-red--text base',
          action: openDialog,
        });
      }

      return actions;
    });

    const isDropdownMenuVisible = computed(() => dropdownMenuActions.value.length > 0);

    const primaryButtonType = computed<IPrimaryButtonType>(() => {
      switch (true) {
        case isRestartAvailable.value:
          return IPrimaryButtonType.RESTART;
        case isAssignAvailable.value:
          return IPrimaryButtonType.ASSIGN;
        case isStartAvailable.value:
          return IPrimaryButtonType.START;
        case isContinueAvailable.value:
          return IPrimaryButtonType.CONTINUE;
        case isResultsAvailable.value:
          return IPrimaryButtonType.RESULTS;
        default:
          return IPrimaryButtonType.UNKNOWN;
      }
    });

    const isUnknownSessionStatus = computed(() => {
      return primaryButtonType.value === IPrimaryButtonType.UNKNOWN;
    });

    const isPrimaryButtonVisible = computed(() => {
      return !isUnknownSessionStatus.value;
    });

    const primaryButtonAttributes = computed(() => ({
      dataTestLabel: primaryButtonType.value,
    }));

    const primaryButtonText = computed(() => {
      switch (primaryButtonType.value) {
        case IPrimaryButtonType.RESTART:
          return t('AtomPreview.actions.restart');
        case IPrimaryButtonType.ASSIGN:
          return t('AtomPreview.actions.assign');
        case IPrimaryButtonType.START:
          return t('AtomPreview.actions.start');
        case IPrimaryButtonType.CONTINUE:
          return t('AtomPreview.actions.continue');
        case IPrimaryButtonType.RESULTS:
          return t('AtomPreview.actions.results');
        default:
          return '';
      }
    });

    return {
      t,
      atomCoverUrl,
      atomHasProviders,
      atomDurationFormatted,
      isImageLoading,
      onLoadStartHandler,
      onLoadErrorHandler,
      onClickPrimaryActionHandler,
      onClickUnassignHandler,
      dropdownMenuActions,
      primaryButtonAttributes,
      setUnassignDialogRef,
      rejectDialog,
      primaryButtonType,
      primaryButtonText,
      isDropdownMenuVisible,
      isUnknownSessionStatus,
      isPrimaryButtonVisible,
    };
  },
});
