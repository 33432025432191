
import {
  computed, defineComponent, onMounted, PropType, ref, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import TTBackButton from '@/components/ui/TTBackButton.vue';
import UpButton from '@/components/ui/UpButton.vue';
import { IBreadcrumb } from '@/domains/common';
import { Names } from '@/plugins/vue-router';
import { useI18n } from '@/plugins/vue-i18n';
import { AtomPreview } from '@/components/AtomPreview';
import { useCatalogueAtom } from '@/domains/atom/composables';
import { assign, assignmentRun } from '@/services/api/lxp-assignment';
import AtomPreviewCoverPlaceholder from '@/components/ui/AtomCoverPlaceholder/AtomCoverPlaceholder.vue';

export default defineComponent({
  name: 'CatalogueAtomPreview',

  components: {
    TTBackButton,
    UpButton,
    AtomPreview,
    AtomPreviewCoverPlaceholder,
  },

  props: {
    catalogueAtomId: {
      type: String,
      required: true,
    },
    assignmentUserId: {
      type: Number as PropType<number | null>,
      required: false,
      default: null,
    },
  },

  setup(props) {
    const { catalogueAtomId, assignmentUserId: assignmentUserIdParam } = toRefs(props);

    const { t } = useI18n();
    const router = useRouter();

    const isLoading = ref(true);
    const isAssigning = ref(false);

    const { isLoading: isAtomLoading, atom, fetchAtom } = useCatalogueAtom({ atomId: catalogueAtomId });

    async function assignAtom() {
      const assignment = await assign({
        paths: {
          catalogueAtomId: catalogueAtomId.value,
        },
      });
      return assignment.assignmentUserId;
    }

    const onAssignHandler = async () => {
      isAssigning.value = true;

      try {
        let assignmentUserId: number;
        if (assignmentUserIdParam.value) {
          assignmentUserId = assignmentUserIdParam.value;
        } else {
          assignmentUserId = await assignAtom();
        }

        const { playerSessionId, atomType } = await assignmentRun({
          paths: {
            assignmentUserId,
          },
          data: {
            catalogueAtomMainId: catalogueAtomId.value,
          },
        });

        return router.push({
          name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
          params: {
            atomType,
            playerSessionId: String(playerSessionId),
          },
        });
      } catch (e: any) {
        console.error(e);
        // TODO: добавить оповещение для пользователя
        // TODO: понять что делаешь говно
        // TODO: пойти на бек и поставить задачу по поиску назначений по catalogueAtomId
        // откуда получить playerSessionId и перевести пользователя на страницу
        // R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW
      } finally {
        isAssigning.value = false;
      }

      return undefined;
    };

    const breadcrumbs = computed<IBreadcrumb[]>(() => [
      {
        text: t('LearningTracks.title'),
        to: {
          name: Names.R_APP_LEARNING_TRACKS,
        },
        'data-test-label': 'my-program-link',
      },
      {
        text: atom.value?.name ?? '',
        'data-test-label': 'program-name-link',
        disabled: true,
      },
    ]);

    onMounted(async () => {
      fetchAtom()
        .catch((e: any) => {
          console.error(e);
        })
        .finally(() => {
          isLoading.value = false;
        });
    });

    const onClickBackButtonHandler = () => {};

    return {
      isLoading,
      isAssigning,
      isAtomLoading,
      atom,
      breadcrumbs,
      onClickBackButtonHandler,
      onAssignHandler,
    };
  },
});
